import { Carousel } from "rsuite";
import { NavLink } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";

import "./carousel.scss";
export default function AutoPlayCarousel({ items }: any) {
  return (
    <Carousel autoplay className="custom-slider">
      {items.map((item: any) => (
        <NavLink to={`article/${item.index}`} className="link">
          <p className="title">{item.title}</p>
          <img src={item.img} key={item.index} alt="s" className="slider_img" />
          <div className="shadow_box"></div>
        </NavLink>
      ))}
    </Carousel>
  );
}
