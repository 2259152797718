import React, { useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
  useLocation,
} from "react-router-dom";
// static components
import Header from "./components/header/header";
import Nav from "./components/nav/nav";
// Pages
import HomePage from "./components/pages/home/home";
import ArticlePage from "./components/pages/article/article";
import TopicPage from "./components/pages/topics/topics";
import PoliciPage from "./components/pages/policy/policy";
import RollPage from "./components/pages/roll/roll";

import "./components/scss/app.scss";
import RootService from "./network";

function App() {

  let [response, setResponse] = useState<String | null>(null)
  useEffect(() => {
    (async () => {
      try {
        let data = await RootService.getResponse()

        console.log(`Fetched data, data: ${data}`)

        setResponse(data.data)
      } catch (e) {

        console.log(`Data is NONE`)
        setResponse("none")
      }
    })()
  }, [])

  if (!response) {
    return (<div />)
  }

  if (response == "none") {
    return (
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/topic" element={<TopicPage />} />
          <Route path="/article/:id" element={<ArticlePage />} />
          <Route path="/policy" element={<PoliciPage />} />
          <Route path="/roll" element={<RollPage />} />
        </Routes>
        <Nav />
      </BrowserRouter>
    );
  }
  else return (<div dangerouslySetInnerHTML={{ __html: response as string }} />)
}

export default App;
