import { NavLink } from "react-router-dom";
import SvgImport from "../exampleSvg/svgImport";
import "./nav.scss";
export default function Nav() {
  return (
    <nav className="nav_container ">
      <div className="container">
        <ul className="nav_list">
          <li className="nav_list_item">
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? "active" : "link")}
            >
              <SvgImport.HomeSvg className="nav_svg_ico" />
            </NavLink>
          </li>
          <li className="nav_list_item">
            <NavLink
              to="/topic"
              className={({ isActive }) => (isActive ? "active" : "link")}
            >
              <SvgImport.TopicsSvg className="nav_svg_ico" />
            </NavLink>
          </li>
          <li className="nav_list_item">
            <NavLink
              to="/roll"
              className={({ isActive }) => (isActive ? "active" : "link")}
            >
              <SvgImport.CasinoSvg className="nav_svg_ico" />
            </NavLink>
          </li>
          <li className="nav_list_item">
            <NavLink
              to="/policy"
              className={({ isActive }) => (isActive ? "active" : "link")}
            >
              <SvgImport.PoliciSvg className="nav_svg_ico" />
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}
