import { ReactComponent as CasinoSvg } from "./roll.svg";
import { ReactComponent as PoliciSvg } from "./policy.svg";
import { ReactComponent as TopicsSvg } from "./articles.svg";
import { ReactComponent as HomeSvg } from "./home.svg";
import { ReactComponent as SearchSvg } from "./search.svg";



const Icons = {
  CasinoSvg,
  PoliciSvg,
  TopicsSvg,
  HomeSvg,
  SearchSvg
};
export default Icons;

