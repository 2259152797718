import DiceGame from "./dice";
import "./roll.scss";

const CasinoPage = () => {
  const initialDiceImage = "/diceGame/perspective-dice-six-faces-random.png";
  const diceImages = [
    "/diceGame/dice-six-faces-one.png",
    "/diceGame/dice-six-faces-two.png",
    "/diceGame/dice-six-faces-three.png",
    "/diceGame/dice-six-faces-four.png",
    "/diceGame/dice-six-faces-five.png",
    "/diceGame/dice-six-faces-six.png",
  ];

  return (
    <div className="game_section container">
      <DiceGame initialDiceImage={initialDiceImage} diceImages={diceImages} />
    </div>
  );
};
export default CasinoPage;
