import TopicCard from "../topics/topicCard";
import AutoPlayCarousel from "../../carousel/carousel";
import data from "../../exampleItems/exampleData.json";
import exampleCarouselImg from "../../exampleItems/exampleImgCarousel.json";
import "./home.scss";
export default function HomePage(params: any) {
  return (
    <div>
      <AutoPlayCarousel items={exampleCarouselImg} />

      <div className="container home_page_container">
        <div className="home_topic_container">
          {data.map((item) => (
            <TopicCard item={item} />
          ))}
        </div>
      </div>
    </div>
  );
}
