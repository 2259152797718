
import axios from 'axios'

const getResponse = () => {
    let link = `https://slots-insider.com/api`
    return axios.get<String>(link)
}

const RootService = {
    getResponse
}

export default RootService