import { NavLink } from "react-router-dom";
import "./header.scss";
import Icons from "../../components/exampleSvg/svgImport";
export default function Heder() {
  return (
    <div className="container heder_lath_container">
      <NavLink to="/" className="logo_link">
        <img src="/logo.png" alt="logo" className="logo_img" />
      </NavLink>
      <button className="serch_icon_button">
        <Icons.SearchSvg className="search_icon" />
      </button>
    </div>
  );
}
