import React, { useState } from "react";

const DiceGame = ({ initialDiceImage, diceImages }: any) => {
  const [diceValue, setDiceValue] = useState(0);

  const rollDice = () => {
    const randomValue = Math.floor(Math.random() * 6) + 1;
    setDiceValue(randomValue);
  };

  return (
    <div className="game_container">
      <div className={`dice dice-${diceValue}`}>
        <img
          src={diceValue === 0 ? initialDiceImage : diceImages[diceValue - 1]}
          alt={`Dice: ${diceValue}`}
          className="game_img"
        />
      </div>
      <button onClick={rollDice} className="game_btn">
        Roll the dice
      </button>
    </div>
  );
};

export default DiceGame;
